
import Vue from 'vue';
import { GaType } from '../../../../store/modules/linearadmin/types/google';
import { RefreshToSeeUpdatedDate } from '../constants';

export default Vue.extend({
  name: 'linearAdminGoogleAnalytics',
  props: ['advertiser', 'gaConnectors', 'editRights', 'action', 'selectedGA'],
  components: {
    GoogleAnalyticsActions: () => import('./googleAnalyticsActionsNew.vue'),
    GaAccounts: () => import('./gaAccountsNew.vue'),
  },
  data: (): {
    isAccountLoading: boolean;
    gaAccounts: GaType | null;
    RefreshToSeeUpdatedDate: string;
  } => ({
    gaAccounts: null,
    isAccountLoading: false,
    RefreshToSeeUpdatedDate,
  }),
  mounted() {
    this.loadGAAccounts();
  },
  watch: {
    showSendEmailForm: {
      handler(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).adminApp.$emit('admin-drawer-toggled');
      },
    },
  },
  methods: {
    selectView(accountId: number, propertyId: number, viewId: number): void {
      this.$emit('select-view', accountId, propertyId, viewId);
    },
    editAdvertiser(input: string): void {
      this.$emit('edit-advertiser', input);
    },
    async loadGAAccounts(): Promise<void> {
      this.isAccountLoading = true;
      await this.$store
        .dispatch('linearAdmin/getGaAvailableAccount', {
          id: this.$route.query.ppid,
        })
        .then(data => {
          this.gaAccounts = data;
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .finally(() => {
          this.isAccountLoading = false;
        });
    },
  },
  computed: {
    gaConnected(): boolean {
      return !!this.gaConnectors;
    },
    gaAccountConnected(): boolean {
      return (
        !this.$store.state.linearAdmin?.googleAnalyticsAccountError &&
        this.$store.state.linearAdmin?.googleAnalyticsAccount
      );
    },
    gaSelectionAccount(): string {
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.ga_account_id) {
        return this.gaConnectors?.additional?.ga_account_id;
      } else if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.account) {
        return `${this.gaConnectors?.additional?.account?.match(/\d+/)[0]}`;
      } else if (this.gaConnectors?.additional?.type === 'ga') {
        return `${this.gaConnectors?.additional?.accountId || this.gaConnectors?.additional?.ga_account_id}`;
      } else {
        return '';
      }
    },
    gaSelectionProperty(): string {
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.account) {
        return `${this.gaConnectors?.additional?.displayName} (${this.gaConnectors?.primaryId})`;
      } else if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.ga_account_id) {
        return `${this.gaConnectors?.primaryId}`;
      } else if (this.gaConnectors?.additional?.type === 'ga') {
        return `${this.gaConnectors?.additional?.id || this.gaConnectors?.additional?.ga_webproperty_id}`;
      } else {
        return '';
      }
    },
    gaSelectionProfile(): string {
      if (this.gaConnectors?.additional?.type === 'ga') {
        return `${this.gaConnectors?.additional?.name || this.gaConnectors?.additional?.ga_view_name} (${
          this.gaConnectors?.primaryId
        })`;
      } else return '';
    },
    notConnected(): object {
      const obj = {
        status: this.advertiser.GASite === true && !this.advertiser.GaHealthy && this.unhealthyGAStatus,
        title: '',
        msg: '',
      };

      if (!this.gaConnectors) obj.title = 'Loading';
      else if (obj.status) obj.title = 'GA not connected';
      else if (this.gaRequestStatus === 'pending') obj.title = 'GA requested from advertiser';
      else if (this.gaRequestStatus === 'not requested') obj.title = 'Not requested';

      if (obj.status) obj.msg = this.unhealthyGAStatus;

      return obj;
    },
    unhealthyGAStatus(): string {
      if (!this.gaConnectors?.Healthy) {
        if (this.gaConnectors && this.gaConnectors.ViewStatus.toLowerCase() === 'missing')
          return 'A Google Analytics view must be selected';
        if (!this.gaConnectors) return 'Not connected';
      }
      return '';
    },
    gaRequestStatus(): string | null {
      if (this.advertiser.GoogleAnalyticsRequestStatus && this.advertiser.GASite === true) {
        // possible values: Received, Pending, Not Requested
        const emailStatus = this.advertiser.GoogleAnalyticsRequestStatus.toLowerCase();
        switch (emailStatus.toLowerCase()) {
          case 'received':
            return 'received';
          case 'pending':
            return 'Pending';
          case 'not requested':
            return 'Not requested';
          default:
            break;
        }
      }
      return null;
    },
  },
});
